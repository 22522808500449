<template>
    <div class="early">
        <div class="nav">
            <div class="list" :class="active == index ? 'list1' : ''" @click="changeMenu(index)" v-for="(item,index) in navList" :key="index"><div class="name">{{item}}</div></div>
        </div>
        <div class="xmxzw">
           <div class="flex" style="border-radius: 4px;border: 1px solid #0955B3">
               <div @click="changeXmIndex(0) " :class="xmIndex == 0 ? 'xmxz1' : 'xmxz'">全部项目</div>
               <div @click="changeXmIndex(1)" :class="xmIndex == 1 ? 'xmxz1' : 'xmxz'">厅管重点项目</div>
           </div>
            <div @mouseover="showXl = true" @mouseleave="showXl = false" style="position: relative">
                <div class="xl">{{searchYear}}年 <i style="color: #fff" class="el-icon-caret-bottom"></i></div>
                <div class="xlcd" v-if="showXl">
                    <div @click="chooseYear(item)" v-for="(item,index) in roadYear" :key="index">{{item}}</div>
                </div>
            </div>
        </div>
        <div class="bot">
            <div class="left" style="width: 28vw">
                <div>
                    <div class="title">投资计划申请统计</div>
                    <div class="echarts" id="echarts1"></div>
                    <div class="titel2"></div>
                    <div class="l1w">
                        <div class="l1o" :class="list1Index == index ? 'l1oActive' : ''" @click="changeList1(index,item)" v-for="(item,index) in list1" :key="index">{{item}}</div>
                    </div>
                    <div class="flex" style="flex-direction: column;align-items: flex-start">
                        <div class="jdw">
                            <div class="jdn">1</div>
                            <div class="jdt"><img src="../../assets/images/statistics/to.png" alt=""></div>
                            <div class="jdtitle">中央投资</div>
                            <div class="jdp"><el-progress v-if="!isNaN(allzytz/total1)" :stroke-width="9" :text-inside="true" :percentage="accDiv(allzytz,total1)" color="#062969" ></el-progress></div>
                            <div class="jdv">{{allzytz.toFixed(0)}} 万元</div>
                        </div>
                        <div class="jdw">
                            <div class="jdn" style="background: #FF9800">2</div>
                            <div class="jdt"><img src="../../assets/images/statistics/to.png" alt=""></div>
                            <div class="jdtitle">省级补助资金</div>
                            <div class="jdp"><el-progress v-if="!isNaN(allsjbz/total1)" :stroke-width="9" :text-inside="true" :percentage="accDiv(allsjbz,total1)" color="#062969" ></el-progress></div>
                            <div class="jdv">{{allsjbz.toFixed(0)}} 万元</div>
                        </div>
                        <div class="jdw">
                            <div class="jdn" style="background: #FFD200">3</div>
                            <div class="jdt"><img src="../../assets/images/statistics/to.png" alt=""></div>
                            <div class="jdtitle">地方(企业)自筹</div>
                            <div class="jdp"><el-progress v-if="!isNaN(alldfzc/total1)" :stroke-width="9" :text-inside="true" :percentage="accDiv(alldfzc,total1)" color="#062969" ></el-progress></div>
                            <div class="jdv">{{alldfzc.toFixed(0)}} 万元</div>
                        </div>
                        <div class="jdw">
                            <div class="jdn" style="background:#3D8FFF">4</div>
                            <div class="jdt"><img src="../../assets/images/statistics/to.png" alt=""></div>
                            <div class="jdtitle">其他资金</div>
                            <div class="jdp"><el-progress v-if="!isNaN(allqtzj/total1)" :stroke-width="9" :text-inside="true" :percentage="accDiv(allqtzj,total1)" color="#062969" ></el-progress></div>
                            <div class="jdv">{{allqtzj.toFixed(0)}} 万元</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="left">
                <div>
                    <div class="title">累计投资计划下达统计</div>
                    <div class="flex" style="justify-content: space-evenly;margin:30px 0">
                        <div class="mc" style="color: #0078FC">
                            <div class="mcc">
                                <div>{{xdztz}}</div>
                                <div class="mcm">总投资</div>
                            </div>

                        </div>
                        <div class="mc mc1" style="color: #0078FC">
                            <div class="mcc">
                                <div>{{xdzytz}}</div>
                                <div class="mcm">中央投资</div>
                            </div>

                        </div>
                        <div class="mc mc2" style="color: #0078FC">
                            <div class="mcc">
                                <div>{{xdsb}}</div>
                                <div class="mcm">省级补助</div>
                            </div>

                        </div>
                    </div>
                    <div class="l1w">
                        <div class="l1o l11" :class="list2Index == index ? 'l1oActive' : ''" @click="changeList2(index,item)" v-for="(item,index) in list2" :key="index">{{item}}</div>
                    </div>
                    <div class="echarts" id="echarts2"></div>

                </div>
            </div><div class="left">
            <div>
                <div class="title">投资计划执行统计</div>
                <div class="echarts" id="echarts3"></div>
                <div class="title1">其中</div>
               <div class="flex" style="justify-content: space-evenly">
                   <div class="echarts4" id="echarts4"></div>
                   <div class="echarts4" id="echarts5"></div>
               </div>
                <div class="flex" style="justify-content: space-evenly;margin-top: -60px" >
                   <div class="echarts4">
                       <div class="bigName">中央投资</div>
                       <div class="bigM">{{jhzxzytz}} 万元</div>
                   </div>
                    <div class="echarts4" >
                        <div class="bigName">省级补助资金</div>
                        <div class="bigM">{{jhzxsb}} 万元</div>
                    </div>
                </div>
            </div>
        </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "Early",
        data(){
            return{
                tzxdData:[],
                jhzxzytz:0,
                jhzxsb:0,
                xdztz:0,
                xdzytz:0,
                xdsb:0,
                allzytz:0,
                total1:0,
                total3:0,
                allqtzj:0,
                allsjbz:0,
                alldfzc:0,
                jhsqData:[],
                roadYear:['2023'],
                searchYear:2023,
                color:'linear-gradient(90deg, #3D8FFF 0%, #00CCFF 100%)',
                percentage:234.21,
                all:12121,
                showXl: false,
                xmIndex:0,
                showM: false,
                visibleSize:5,
                lineHeight:43,
                componentTimerInterval:3600000,
                tableTimerInterval:100,
                tableTop:0,
                index:0,
                navList:['公路','水运','铁路','民航','枢纽场站'],
                active:0,
                tableList:[1,2,3,4,5,6,7,8,9,10],
                list1:['全部','高速','国道','省道','G331及质量提升工程'],
                list2:['全年','第一批','第二批','公路投资计划'],
                list1Index:0,
                list2Index:0,
                gaugeData: [
                    {
                        value:1,
                        name: '完成率',
                        title: {
                            offsetCenter: ['0%', '30%']
                        },
                        detail: {
                            valueAnimation: true,
                            offsetCenter: ['0%', '-10%']
                        }
                    },

                ],
                gaugeData1: [
                    {
                        value:1,
                        name: '完成率',
                        title: {
                            offsetCenter: ['0%', '30%']
                        },
                        detail: {
                            valueAnimation: true,
                            offsetCenter: ['0%', '-10%']
                        }
                    },

                ],
                crip: true,
            }
        },
        mounted(){
            window.addEventListener("resize", () => {
                // this.myChart1.resize();
                this.myChart2.resize();
                this.myChart3.resize();
                this.myChart4.resize();
                this.myChart5.resize();
            });
            this.getData()
        },
        methods: {
            accDiv(arg1, arg2) {
                console.log(arg1, arg2)
                let t1 = 0
                let t2 = 0
                let r1
                let r2
                try {
                    t1 = arg1.toString().split('.')[1].length
                } catch (e) {
                    console.log(e)
                }
                try {
                    t2 = arg2.toString().split('.')[1].length
                } catch (e) {
                    console.log(e)
                }
                r1 = Number(arg1.toString().replace('.', ''))
                r2 = Number(arg2.toString().replace('.', ''))
                return Number(((r1 / r2)*100).toFixed(0))
            },
            chooseYear(year){
                this.searchYear = year
                this.showXl = false
                this.getData()
            },
            getData(){
                var that = this
                that.xdztz = 0
                this.xdzytz = 0
                this.xdsb = 0
                var searchData = {
                    wheres:JSON.stringify([{  Name:'ND', Value:this.searchYear,}]),
                };
                this.http.post('/api/Plan_filling/FillingStatisticsSummary',searchData).then(res=>{
                    this.jhsqData = res
                    this.tzsq = res['当年投资计划申请统计']
                    this.tzsqxmlb = []
                    this.total1 = 0
                    this.allzytz = 0
                    this.allsjbz = 0
                    this.alldfzc = 0
                    this.allqtzj = 0
                    this.tzsq.map((item,index)=>{
                       item.name = item.XMLB
                       item.value = item.ZTZ
                        that.total1 += item.ZTZ
                        this.allzytz += (item.ZYTZ)
                        this.allsjbz += item.SJBZZJ
                        this.alldfzc += item.DFZC
                        this.allqtzj += item.QT
                    })

                    this.tzxd = res['当年投资计划下达统计']
                    var gs = 0
                    var gd = 0
                    var sd = 0
                    var zlts = 0
                    var tz = 0
                    var a = []
                    this.tzxd.map((item,index)=>{
                        tz+=item.ZTZ
                        item.name = item.XMLB
                        item.value = item.ZTZ
                        if(item.XMLB == "高速公路"){
                            gs+=item.ZTZ
                        }
                        if(item.XMLB == "普通国道"){
                            gd+=item.ZTZ
                        }
                        if(item.XMLB == "普通省道"){
                            sd+=item.ZTZ
                        }
                        if(item.XMLB == "G331及质量提升工程"){
                            zlts+=item.ZTZ
                        }
                        item.name = item.XMLB
                        item.value = item.ZTZ
                        that.xdztz += item.ZTZ
                        this.xdzytz += item.ZYTZ
                        this.xdsb += item.SJBZZJ
                    })
                    this.tzxdData = [{name:'G331及质量提升工程',value:zlts},{name:'普通省道',value:sd},{name:'普通国道',value:gd},{name:'高速公路',value:gs}]

                    this.tzzx = res['当年投资计划执行统计']
                    this.tzzx.map((item,index)=>{
                        if(item.XMLB == '普通省级'){
                            item.XMLB = '普通省道'
                        }
                        item.name = item.XMLB
                        item.value = item.ZTZ
                        that.total3 += item.ZTZ
                        this.jhzxzytz += item.ZYTZ
                        this.jhzxsb += item.SJBZZJ
                    })
                    if (that.total3 === 0){
                        this.gaugeData[0].value = 0
                        this.gaugeData1[0].value = 0
                        this.crip = false
                    }else{
                        this.gaugeData[0].value = this.accDiv(this.jhzxzytz, that.total3)
                        this.gaugeData1[0].value = this.accDiv(this.jhzxsb, that.total3)
                        this.crip = true
                    }
                    this.getEcharts1()
                    this.getEcharts2()
                    this.getEcharts3()
                    this.getEcharts4()
                    this.getEcharts5()

                })
            },
            changeXmIndex(val){
                if(val != 0){
                    this.$message.warning('此功能暂未开放,敬请期待')
                    return false
                }
                this.xmIndex = val
            },
            format(){

            },
            changeList1(index,name){
                var that = this
                this.list1Index = index
                that.total1 = 0
                this.allzytz = 0
                this.allsjbz = 0
                this.alldfzc = 0
                this.allqtzj = 0
                switch (index) {
                    case 0:
                        this.tzsq.map((item,index)=>{
                            that.total1 += item.ZTZ
                            this.allzytz += item.ZYTZ
                            this.allsjbz += item.SJBZZJ
                            this.alldfzc += item.DFZC
                            this.allqtzj += item.QT
                        })
                        break;
                    case 1:
                        this.tzsq.map((item,index)=>{
                            if(item.XMLB == '高速公路'){
                                that.total1 = item.ZTZ
                                this.allzytz = item.ZYTZ
                                this.allsjbz = item.SJBZZJ
                                this.alldfzc = item.DFZC
                                this.allqtzj = item.QT
                            }

                        })
                        break;
                    case 2:
                        this.tzsq.map((item,index)=>{
                            if(item.XMLB == '普通国道'){
                                that.total1 = item.ZTZ
                                this.allzytz = item.ZYTZ
                                this.allsjbz = item.SJBZZJ
                                this.alldfzc = item.DFZC
                                this.allqtzj = item.QT
                            }

                        })
                        break;
                    case 3:
                        console.log('点省道了')
                        this.tzsq.map((item,index)=>{
                            if(item.XMLB == '普通省道'){
                                that.total1 = item.ZTZ
                                that.allzytz = item.ZYTZ == null ? 0: item.ZYTZ
                                that.allsjbz = item.SJBZZJ
                                that.alldfzc = item.DFZC
                                that.allqtzj = item.QT
                            }

                        })
                        break;
                    case 4:
                        this.tzsq.map((item,index)=>{
                            if(item.XMLB == 'G331及质量提升工程'){
                                that.total1 = item.ZTZ
                                this.allzytz = item.ZYTZ
                                this.allsjbz = item.SJBZZJ
                                this.alldfzc = item.DFZC
                                this.allqtzj = item.QT
                            }

                        })
                        break;
                }
              // this.jhsqfl = this.jhsqData['当年投资计划申请统计'].find(item=>item.XMLB == name)
                this.jhsqData['当年投资计划申请统计'].find(item=>{
                    if (item.XMLB == name){
                        this.jhsqfl = item
                    }

                })

            },
            changeList2(index,name){
                console.log(index)
                var that = this
                this.list2Index = index
                var tz = 0
                switch (index) {
                    case 0:
                        var a  = []
                        var gs = 0
                        var gd = 0
                        var sd = 0
                        var zlts = 0
                        this.tzxd.map((item,index)=>    {
                            tz+=item.ZTZ
                            item.name = item.XMLB
                            item.value = item.ZTZ
                            if(item.XMLB == "高速公路"){
                                gs+=item.ZTZ
                            }
                            if(item.XMLB == "普通国道"){
                                gd+=item.ZTZ
                            }
                            if(item.XMLB == "普通省道"){
                                sd+=item.ZTZ
                            }
                            if(item.XMLB == "G331及质量提升工程"){
                                zlts+=item.ZTZ
                            }
                        })
                        a = [{name:'G331及质量提升工程',value:zlts},{name:'普通省道',value:sd},{name:'普通国道',value:gd},{name:'高速公路',value:gs}]
                        this.myChart2.setOption({
                            tooltip: {
                                trigger: 'item',
                                formatter: '{b} : {d}%',
                            },
                            icon: 'squire', // 图例色块是方还是圆
                            title: {
                                // backgroundColor:'#001F67',
                                // borderRadius:100,
                                // padding:40,
                                left:'middle',
                                top:'30%',
                                itemGap:20,
                                text: tz,
                                subtext: '总投资(万元)',
                                textStyle: {
                                    fontSize: 28,
                                    color: "#ffffff",
                                    fontWeight: 500,
                                },
                                subtextStyle: {
                                    fontSize: 16,
                                    color: "#ffffff",
                                    fontWeight: 400,
                                    lineHeight:0,
                                },
                                textAlign: "center", //图例文字居中显示
                                x: "44.5%",   //距离左边的距离
                                y: "17%"    //距离上边的距离
                            },
                            color:['#EB6F49','#1ED8F4','#FF5C5C','#0070FB'],
                            legend: {
                                textStyle:{
                                    color:'#fff',
                                },
                                bottom:30,
                                itemGap: 23,
                            },
                            series: [
                                {
                                    name: '',
                                    type: 'pie',
                                    radius: ['45%', '60%'],
                                    center: ['50%', '40%'],
                                    label: {
                                        show: true,
                                        color:'#ffffff',
                                        labelLine:{
                                            lineStyle:{
                                                color:['#EB6F49','#1ED8F4','#FF5C5C','#0070FB'],
                                            }
                                        },
                                        formatter: "{name|{b}}\n{value|{c}}",
                                        rich:{
                                            value:{
                                                fontSize:16,
                                                color:"#fff"
                                            }
                                        }
                                    },
                                    emphasis: {
                                        label: {
                                            show: true,
                                            fontSize: '16',
                                            fontWeight: 'bold',
                                        }
                                    },
                                    labelLine: {
                                        show: true
                                    },
                                    data: a,
                                }
                            ]
                        })
                        console.log( this.tzxd)
                        break;
                    case 1:
                        var b = []
                        this.tzxd.map((item,index)=>{
                            if(item.JHPC == "第一批"){
                                item.name = item.XMLB
                                item.value = item.ZTZ
                                tz+=item.ZTZ
                                b.push(item)
                            }
                        })
                        console.log(b)
                        this.myChart2.setOption({
                            tooltip: {
                                trigger: 'item',
                                formatter: '{b} : {d}%',
                            },
                            icon: 'squire', // 图例色块是方还是圆
                            title: {
                                // backgroundColor:'#001F67',
                                // borderRadius:100,
                                // padding:40,
                                left:'middle',
                                top:'30%',
                                itemGap:20,
                                text: tz,
                                subtext: '总投资(万元)',
                                textStyle: {
                                    fontSize: 28,
                                    color: "#ffffff",
                                    fontWeight: 500,
                                },
                                subtextStyle: {
                                    fontSize: 16,
                                    color: "#ffffff",
                                    fontWeight: 400,
                                    lineHeight:0,
                                },
                                textAlign: "center", //图例文字居中显示
                                x: "44.5%",   //距离左边的距离
                                y: "17%"    //距离上边的距离
                            },
                            color:['#EB6F49','#1ED8F4','#FF5C5C','#0070FB'],
                            legend: {
                                textStyle:{
                                    color:'#fff',
                                },
                                bottom:30,
                                itemGap: 23,
                            },
                            series: [
                                {
                                    name: '',
                                    type: 'pie',
                                    radius: ['45%', '60%'],
                                    center: ['50%', '40%'],
                                    label: {
                                        show: true,
                                        color:'#ffffff',
                                        labelLine:{
                                            lineStyle:{
                                                color:['#EB6F49','#1ED8F4','#FF5C5C','#0070FB'],
                                            }
                                        },
                                        formatter: "{name|{b}}\n{value|{c}}",
                                        rich:{
                                            value:{
                                                fontSize:16,
                                                color:"#fff"
                                            }
                                        }
                                    },
                                    emphasis: {
                                        label: {
                                            show: true,
                                            fontSize: '16',
                                            fontWeight: 'bold',
                                        }
                                    },
                                    labelLine: {
                                        show: true
                                    },
                                    data: b,
                                }
                            ]
                        })
                        console.log( this.tzxd)
                        break;
                    case 2:
                        var c = []
                        this.tzxd.map((item,index)=>{
                            if(item.JHPC == '第二批'){
                                // if(item.XMLB == '普通省级'){
                                //     item.XMLB = '普通省道'
                                // }
                                tz+=item.ZTZ
                                item.name = item.XMLB
                                item.value = item.ZTZ
                                c.push(item)
                            }
                        })
                        console.log(c)
                        if (c.length == 0){
                            console.log(1212121232324)
                            c = [{'G331及质量提升工程':0},{'普通国道':0},{'普通省道':0},{'高速公路':0}]
                        }
                        this.myChart2.setOption({
                            tooltip: {
                                trigger: 'item',
                                formatter: '{b} : {d}%',
                            },
                            icon: 'squire', // 图例色块是方还是圆
                            title: {
                                // backgroundColor:'#001F67',
                                // borderRadius:100,
                                // padding:40,
                                left:'middle',
                                top:'30%',
                                itemGap:20,
                                text: tz,
                                subtext: '总投资(万元)',
                                textStyle: {
                                    fontSize: 28,
                                    color: "#ffffff",
                                    fontWeight: 500,
                                },
                                subtextStyle: {
                                    fontSize: 16,
                                    color: "#ffffff",
                                    fontWeight: 400,
                                    lineHeight:0,
                                },
                                textAlign: "center", //图例文字居中显示
                                x: "44.5%",   //距离左边的距离
                                y: "17%"    //距离上边的距离
                            },
                            color:['#EB6F49','#1ED8F4','#FF5C5C','#0070FB'],
                            legend: {
                                textStyle:{
                                    color:'#fff',
                                },
                                bottom:30,
                                itemGap: 23,
                            },
                            series: [
                                {
                                    name: '',
                                    type: 'pie',
                                    radius: ['45%', '60%'],
                                    center: ['50%', '40%'],
                                    label: {
                                        show: true,
                                        color:'#ffffff',
                                        labelLine:{
                                            lineStyle:{
                                                color:['#EB6F49','#1ED8F4','#FF5C5C','#0070FB'],
                                            }
                                        },
                                        formatter: "{name|{b}}\n{value|{c}}",
                                        rich:{
                                            value:{
                                                fontSize:16,
                                                color:"#fff"
                                            }
                                        }
                                    },
                                    emphasis: {
                                        label: {
                                            show: true,
                                            fontSize: '16',
                                            fontWeight: 'bold',
                                        }
                                    },
                                    labelLine: {
                                        show: true
                                    },
                                    data: c,
                                }
                            ]
                        })
                        console.log( this.tzxd)
                        break;
                    case 3:
                        var d = []
                        this.tzxd.map((item,index)=>{
                            if(item.JHPC == '公路投资计划'){
                                tz+=item.ZTZ
                                if (item.ZTZ != 0){
                                    item.name = item.XMLB
                                    item.value = item.ZTZ
                                    // d.push({[item.XMLB]:item.ZTZ})

                                    d.push(item)
                                }
                            }
                        })
                        if (d.length == 0){
                            console.log(1212121232324)
                            d = [{'G331及质量提升工程':0},{'普通国道':0},{'普通省道':0},{'高速公路':0}]
                        }
                        console.log(d)
                        this.myChart2.setOption({
                            tooltip: {
                                trigger: 'item',
                                formatter: '{b} : {d}%',
                            },
                            icon: 'squire', // 图例色块是方还是圆
                            title: {
                                // backgroundColor:'#001F67',
                                // borderRadius:100,
                                // padding:40,
                                left:'middle',
                                top:'30%',
                                itemGap:20,
                                text: tz,
                                subtext: '总投资(万元)',
                                textStyle: {
                                    fontSize: 28,
                                    color: "#ffffff",
                                    fontWeight: 500,
                                },
                                subtextStyle: {
                                    fontSize: 16,
                                    color: "#ffffff",
                                    fontWeight: 400,
                                    lineHeight:0,
                                },
                                textAlign: "center", //图例文字居中显示
                                x: "44.5%",   //距离左边的距离
                                y: "17%"    //距离上边的距离
                            },
                            color:['#EB6F49','#1ED8F4','#FF5C5C','#0070FB'],
                            legend: {
                                textStyle:{
                                    color:'#fff',
                                },
                                bottom:30,
                                itemGap: 23,
                            },
                            series: [
                                {
                                    name: '',
                                    type: 'pie',
                                    radius: ['45%', '60%'],
                                    center: ['50%', '40%'],
                                    label: {
                                        show: true,
                                        color:'#ffffff',
                                        labelLine:{
                                            lineStyle:{
                                                color:['#EB6F49','#1ED8F4','#FF5C5C','#0070FB'],
                                            }
                                        },
                                        formatter: "{name|{b}}\n{value|{c}}",
                                        rich:{
                                            value:{
                                                fontSize:16,
                                                color:"#fff"
                                            }
                                        }
                                    },
                                    emphasis: {
                                        label: {
                                            show: true,
                                            fontSize: '16',
                                            fontWeight: 'bold',
                                        }
                                    },
                                    labelLine: {
                                        show: true
                                    },
                                    data: d,
                                }
                            ]
                        })
                        console.log( this.tzxd)
                        break;
                }
                // this.jhsqfl = this.jhsqData['当年投资计划申请统计'].find(item=>item.XMLB == name)
                this.jhsqData['当年投资计划申请统计'].find(item=>{
                    if (item.XMLB == name){
                        this.jhsqfl = item
                    }

                })

            },
            changeMenu(index) {
                if(index != 0){
                    this.$message.warning('此功能暂未开放,敬请期待')
                    return false
                }
                this.active = index
            },
            //公路
            getEcharts3() {
                var that = this
                this.myChart3 = this.$echarts.init(
                    document.getElementById("echarts3")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b} : {c}(万元)',
                    },
                    icon: 'squire', // 图例色块是方还是圆
                    title: {
                        // backgroundColor:'#001F67',
                        // borderRadius:100,
                        // padding:40,
                        left:'middle',
                        top:'30%',
                        itemGap:20,
                        text: that.total3,
                        subtext: '总投资(亿元)',
                        textStyle: {
                            fontSize: 20,
                            color: "#ffffff",
                            fontWeight: 500,
                        },
                        subtextStyle: {
                            fontSize: 16,
                            color: "#ffffff",
                            fontWeight: 400,
                            lineHeight:0,
                        },
                        textAlign: "center", //图例文字居中显示
                        x: "44.5%",   //距离左边的距离
                        y: "17%"    //距离上边的距离
                    },
                    color:['#0070FB','#FF5C5C','#1ED8F4'],
                    legend: {
                        textStyle:{
                            color:'#fff',
                        },
                        bottom:30,
                        itemGap: 23,
                    },
                    series: [
                        {
                            name: '',
                            type: 'pie',
                            radius: ['45%', '60%'],
                            center: ['50%', '40%'],
                            label: {
                                show: true,
                                color:'#ffffff',
                                labelLine:{
                                    lineStyle:{
                                        color:['#0070FB','#FF5C5C','#1ED8F4']
                                    }
                                },
                                formatter: "{value|{c}}",
                                rich:{
                                    value:{
                                        fontSize:16,
                                        color:"#fff"
                                    }
                                }
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '16',
                                    fontWeight: 'bold',
                                }
                            },
                            labelLine: {
                                show: true
                            },
                            data:that.tzzx
                        }
                    ]
                };
                this.myChart3.setOption(pieoption);

            },
            getEcharts2() {
                var that = this
                this.myChart2 = this.$echarts.init(
                    document.getElementById("echarts2")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b} : {d}%',
                    },
                    icon: 'squire', // 图例色块是方还是圆
                    title: {
                        // backgroundColor:'#001F67',
                        // borderRadius:100,
                        // padding:40,
                        left:'middle',
                        top:'30%',
                        itemGap:20,
                        text: that.xdztz,
                        subtext: '总投资(万元)',
                        textStyle: {
                            fontSize: 20,
                            color: "#ffffff",
                            fontWeight: 500,
                        },
                        subtextStyle: {
                            fontSize: 16,
                            color: "#ffffff",
                            fontWeight: 400,
                            lineHeight:0,
                        },
                        textAlign: "center", //图例文字居中显示
                        x: "44.5%",   //距离左边的距离
                        y: "17%"    //距离上边的距离
                    },
                    color:['#EB6F49','#1ED8F4','#FF5C5C','#0070FB'],
                    legend: {
                        textStyle:{
                            color:'#fff',
                        },
                        bottom:30,
                        itemGap: 23,
                    },
                    series: [
                        {
                            name: '',
                            type: 'pie',
                            radius: ['45%', '60%'],
                            center: ['50%', '40%'],
                            label: {
                                show: true,
                                color:'#ffffff',
                                labelLine:{
                                    lineStyle:{
                                        color:['#EB6F49','#1ED8F4','#FF5C5C','#0070FB'],
                                    }
                                },
                                formatter: "{name|{b}}\n{value|{c}}",
                                rich:{
                                    value:{
                                        fontSize:16,
                                        color:"#fff"
                                    }
                                }
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '16',
                                    fontWeight: 'bold',
                                }
                            },
                            labelLine: {
                                show: true
                            },
                            data: that.tzxdData,
                        }
                    ]
                };
                this.myChart2.setOption(pieoption);

            },
            getEcharts1() {
                var that = this
                this.myChart1 = this.$echarts.init(
                    document.getElementById("echarts1")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b} : {c}(万元)',
                    },
                    icon: 'squire', // 图例色块是方还是圆
                    title: {
                        // backgroundColor:'#001F67',
                        // borderRadius:100,
                        // padding:40,
                        left:'middle',
                        top:'30%',
                        itemGap:20,
                        text: that.total1.toFixed(2),
                        subtext: '总投资(万元)',
                        textStyle: {
                            fontSize: 20,
                            color: "#ffffff",
                            fontWeight: 500,
                        },
                        subtextStyle: {
                            fontSize: 16,
                            color: "#ffffff",
                            fontWeight: 400,
                            lineHeight:0,
                        },
                        textAlign: "center", //图例文字居中显示
                        x: "44.5%",   //距离左边的距离
                        y: "17%"    //距离上边的距离
                    },
                    color:['#EB6F49','#1ED8F4','#FF5C5C','#0070FB'],
                    legend: {
                       textStyle:{
                             color:'#fff',
                       },
                        bottom:30,
                        itemGap: 23,
                    },
                    series: [
                        {
                            name: '',
                            type: 'pie',
                            radius: ['45%', '60%'],
                            center: ['50%', '40%'],
                            label: {
                                show: true,
                                color:'#ffffff',
                                labelLine:{
                                    lineStyle:{
                                        color:['#EB6F49','#1ED8F4','#FF5C5C','#0070FB'],
                                    }
                                },
                                formatter: "{value|{d}%}",
                                rich:{
                                    value:{
                                        fontSize:14,
                                        color:"#fff"
                                    }
                                }
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '16',
                                    fontWeight: 'bold',
                                }
                            },
                            labelLine: {
                                show: true
                            },
                            data: that.tzsq
                        }
                    ]
                };
                this.myChart1.setOption(pieoption);

            },
            getEcharts4() {
                var that = this
                this.myChart4 = this.$echarts.init(
                    document.getElementById("echarts4")
                );
                var pieoption  = {
                    series: [
                        {
                            type: 'gauge',
                            startAngle: 210,
                            endAngle: -30,
                            itemStyle: {
                                normal:{
                                    color:{
                                        type: 'linear',
                                        x: 0,
                                        y: 0,
                                        x2: 0,
                                        y2: 1,
                                        colorStops: [{
                                            offset: 0, color: '#4631F8' // 0% 处的颜色
                                        }, {
                                            offset: 1, color: '#54FFF1' // 100% 处的颜色
                                        }],
                                        global: false // 缺省为 false
                                    },
                                }
                            },
                            pointer: {
                                show: false
                            },
                            progress: {
                                show: true,
                                overlap: false,
                                roundCap: false,
                                clip: that.crip,
                                itemStyle: {

                                }
                            },
                            axisLine: {
                                lineStyle: {
                                    width: 10,
                                }
                            },
                            splitLine: {
                                show: false,
                                distance: 0,
                                length: 10
                            },
                            axisTick: {
                                show: false
                            },
                            axisLabel: {
                                show: false,
                                distance: 50
                            },
                            data: that.gaugeData,
                            title: {
                                color:'#fff',
                                fontSize: 14
                            },
                            detail: {
                                width: 50,
                                height: 14,
                                fontSize: 28,
                                color: '#fff',
                                borderColor: 'auto',
                                formatter: '{value}%'
                            }
                        }
                    ]
                };
                this.myChart4.setOption(pieoption);

            },
            getEcharts5() {
                var that = this
                this.myChart5 = this.$echarts.init(
                    document.getElementById("echarts5")
                );
                var pieoption = {
                    series: [
                        {
                            type: 'gauge',
                            startAngle: 210,
                            endAngle: -30,
                            itemStyle: {
                                normal:{
                                    color:{
                                        type: 'linear',
                                        x: 0,
                                        y: 0,
                                        x2: 0,
                                        y2: 1,
                                        colorStops: [{
                                            offset: 0, color: '#4631F8' // 0% 处的颜色
                                        }, {
                                            offset: 1, color: '#54FFF1' // 100% 处的颜色
                                        }],
                                        global: false // 缺省为 false
                                    },
                                }
                            },
                            pointer: {
                                show: false
                            },
                            progress: {
                                show: true,
                                overlap: false,
                                roundCap: false,
                                clip:  that.crip,
                                itemStyle: {

                                }
                            },
                            axisLine: {
                                lineStyle: {
                                    width: 10,
                                }
                            },
                            splitLine: {
                                show: false,
                                distance: 0,
                                length: 10
                            },
                            axisTick: {
                                show: false
                            },
                            axisLabel: {
                                show: false,
                                distance: 50
                            },
                            data: that.gaugeData1,
                            title: {
                                color:'#fff',
                                fontSize: 14
                            },
                            detail: {
                                width: 50,
                                height: 14,
                                fontSize: 28,
                                color: '#fff',
                                borderColor: 'auto',
                                formatter: '{value}%'
                            }
                        }
                    ]
                };
                this.myChart5.setOption(pieoption);

            },
        }

    }
</script>

<style scoped lang="less">
    .early{
        .nav{
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            .list{
                margin-right: 12px;
                cursor: pointer;
                line-height: 34px;
                width: 106px;
                height: 34px;
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #FFFFFF;
                background: url("../../assets/images/statistics/bg-menu.png");
                background-size: 106px 34px;
                text-align: center;
            }
            .list1{
                color: #00FFFF;
                background: url("../../assets/images/statistics/bg-menu-active.png");
                background-size: 106px 34px;
            }
        }
        .xmxzw{
            margin: 22px 0 0 30px;
            display: flex;
            align-items: center;
            .xmxz{
                width: 131px;
                height: 36px;
                line-height: 36px;
                color: #ffffff;
                background: rgba(9,85,179,0.3000);
                text-align: center;
            }
            .xmxz1{
                width: 131px;
                height: 36px;
                line-height: 36px;
                color: #ffffff;
                text-align: center;
                background: #0955B3;
            }
            .xl{
                margin-left: 14px;
                width: 104px;
                height: 36px;
                background: #0955B3;
                border: 1px solid #0955B3;
                border-radius: 4px;
                text-align: center;
                line-height: 36px;
                color: #fff;
            }
            .xlcd{
                cursor: pointer;
                z-index: 10;
                border: 1px solid #0955B3;
                left: 14px;
                background: #0955B3;
                position: absolute;
                div{
                    width: 104px;
                    height: 36px;
                    text-align: center;
                    line-height: 36px;
                    color: #fff;
                    border-top: 1px solid #fff;
                }
            }
        }
    }
    .bot{
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 24px;
        .left{
            width: 32vw;
            height:75vh;
            background: url("../../assets/images/statistics/bg-jhgl.png");
            background-size: 100% 100%;
            .title{
                margin: 20px 0 20px 20px;
                width: 18vw;
                height:46px;
                background: url("../../assets/images/statistics/bg-tit1.png");
                background-size: 100% 100%;
                font-size: 20px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                text-align: left;
                padding-left: 2vw;
                line-height: 46px;
            }
            .title1{
                font-size: 16px;
                margin: 0 0 20px 23px;
                width: 15vw;
                height:30px;
                background: url("../../assets/images/statistics/tit4-bg.png");
                background-size: 100% 100%;
                line-height: 30px;
                text-align: left;
                padding-left: 20px;
                color: #fff;
            }
            .titel2{
                margin: -10px auto;
                width: 344px;
                height: 43px;
                background: url("../../assets/images/statistics/tit3.png") no-repeat;
                background: 100% 100%;
            }
            .l1w{
                margin:28px 20px;
                display: flex;
                align-items: center;
                .l1o{
                    cursor: pointer;
                    padding: 0 10px;
                    height: 32px;
                    text-align: center;
                    line-height: 32px;
                    color: #fff;
                    font-size: 16px;
                    border: 1px solid rgba(0,0,0,0);
                }
                .l1oActive{
                    border: 1px solid #03BFF2;
                    border-radius: 16px;
                    box-shadow: 0 0 5px #03BFF2;
                }
                .l11{
                    width: auto;
                    padding: 0 15px;
                }
            }
        }

        .echarts{
            width: auto;
            height: 35vh;
        }
        .echarts4{
            width: 50%;
            height: 22vh;
            .bigName{
                text-align: center;
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #FFFFFF;
            }
            .bigM{
                text-align: center;
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #1ED8F4;
            }
        }
        .echarts1{
            width: 65%;
            height: 26vh;
        }
        .echarts2{
            width: auto;
            height: 24vh;
        }
    }
    .flex{
        display: flex;
        align-items: center;
    }
    ::-webkit-scrollbar {
        /*隐藏滚轮*/
        display: none;
    }
    ::v-deep .el-progress-bar__inner{
        background: linear-gradient(90deg, #3D8FFF 0%, #00CCFF 100%);
    }
    ::v-deep .el-progress-bar__outer{
        background: #062969;
    }
    ::v-deep .el-progress-bar{
        padding-right: 20px;
    }
    .jdw{
        margin-bottom: 16px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .jdt{
            width: 24px;
            height: 25px;
            img{
                width: 24px;
                height: 25px;
            }
        }
        .jdn{
            color: #fff;
            font-size: 13px;
            width: 22px;
            height: 19px;
            line-height: 19px;
            text-align: center;
            background: #FF3232;
            border-radius: 2px;
        }
        .jdp{
            width: 10vw;
        }
        .jdtitle{
            width: 120px;
            height: 19px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            color: #FFFFFF;
            line-height: 19px;
        }
        .jdv{
            height: 19px;
            line-height: 19px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;

        }
    }
    .mc{
        width: 149px;
        height: 149px;
        background: url("../../assets/images/statistics/bgq1.png");
        background-size: 100% 100%;
        text-align: center;
        font-size: 20px;
        .mcc{
            margin-top:50px;
        }
        .mcm{
            font-size: 16px;
            color: #fff;
            line-height: unset;
        }
        /*span{*/
        /*    font-size: 16px;*/
        /*    line-height: unset;*/
        /*    color: #ffffff;*/
        /*}*/
    }
    .mc1{
        background: url("../../assets/images/statistics/bgq2.png");
    }
    .mc2{
        background: url("../../assets/images/statistics/bgq3.png");
    }
    .ybp{

    }



    ::v-deep svg>path:nth-child(2) {
        stroke: url(#blue);
    }
    ::v-deep .el-progress-circle__track {
        stroke: rgba(0,122,255,0.3);
    }

    ::v-deep .el-progress__text {
        font-size: 36px !important;;
        font-weight: bolder;
        color: #FFFFFF;
    }
    ::v-deep .el-progress-bar__innerText{
        margin: -9px 5px 0;
    }
</style>
